
@import './index.module.less';

// 邀请会议、人员调度、添加视频
.invite {
  // height: 456px;
  margin-bottom: -10px;
  display: flex;
  flex-direction: row;
  :global {
    * {
      box-sizing: border-box;
    }
    .ant-tabs {
      flex: 1;
    }
    .ant-tree {
      flex: 1;
      overflow: auto;
    }
    .ant-checkbox-wrapper {
      color: var(--ylr-color-white);
    }
    .ant-empty-description {
      color: var(--ylr-color-white);
    }
  }
  > .cont {
    background: rgba(49,165,255,0.1);
    padding: 15px 20px 0 20px;
    height: 700px;
    display: flex;
    flex-direction: column;
    flex: 1;
    &.isFold {
      height: 56px!important;
      .title span, .title::before, .search, > div:last-child {
        display: none;
      }
    }
  }
  .title {
    font-size: 18px;
    color: var(--ylr-color-white);
    margin: 0 0 10px;
    font-weight: bold;
    &::before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 16px;
      background: var(--ylr-color-link);
      border-radius: 2px;
      margin: 0 10px -1px 0;
    }
  }
  .search {
    color: var(--ylr-color-white);
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 10px;
    display: flex;
    em {
      float: right;
      font-style: normal;
      font-size: 14px;
      margin-top: 2px;
    }
    input {
      margin-right: 10px;
      // width: 175px;
      flex: 1;
    }
  }
  .footer {
    background: rgba(16,98,159,0.5);
    color: #95A0B8;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    margin-top: 10px;
  }
  .selected {
    width: 900px;
    margin: 0;
    padding: 0 0 0 18px;
    display: flex;
    flex-direction: column;
    .cont {
      padding: 0;
      margin: 0;
      color: var(--ylr-color-white);
      font-size: 14px;
      line-height: 20px;
      list-style: none;
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 393px;
      :global {
        .ant-empty {
          margin-top: 140px;
        }
      }
      li {
        margin: 0 0 10px;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
          width: 205px;
        }
        em {
          float: right;
          margin: 3px 3px 0 0;
        }
      }
      .playerWrap {
        width: 100%;
        height: 100%;
        position: relative;
      }
      .player {
        background: #000;
        border-radius: 3px;
        border: 1px solid var(--ylr-color-link);
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden;
        video {
          height: 100%;
          width: 100%;
          object-fit: contain!important;
          position: absolute;
        }
        :global {
          .ant-spin {
            top: calc(50% - 16px);
            left: calc(50% - 16px);
            position: absolute;
            z-index: 9;
          }
        }
      }
      .name {
        position: absolute;
        z-index: 1;
        color: #AED7FF;
        left: 18px;
        bottom: 13px;
        font-size: 16px;
      }
      .empty {
        position: absolute;
        font-size: 24px;
        font-weight: 600;
        color: rgba(255,255,255,0.5);
        top: calc(50% - 10px);
        left: calc(50% - 50px);
      }
    }
    .btn {
      margin: 10px 0 20px;
      text-align: right;
    }
    :global{
      .commonForm {
        // height: 174px;
      }
      // 发送时间错误提示
      .ant-form.isSendTime {
        .ant-radio-group{
          position: relative;
          z-index: 9;
        }
        .ant-form-item:last-child .checkboxWrap .ant-form-item-explain-error {
          position: absolute;
          margin: 37px 0 0 0;
        }
      }
    }
  }

  &.isMax {
    > .cont {
      position: absolute;
      z-index: 9;
      background: rgba(9,59,114,0.95);
      height: calc(100% -300px);
      margin: 1px 0 0 1px;
      box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
    }
    .selected {
      width: 100%;
      padding: 0;
    }
  }
}