.commonThemeBlue {
  --ylr-color-default: #005684;
  --ylr-color-default-border: #00A6FF;
  --ylr-color-default-hover: #004b74;
  --ylr-color-primary: #0081FF;
  --ylr-color-primary-border: #00F2FF;
  --ylr-color-primary-hover: #019aec;
  --ylr-color-primary2: rgba(0,156,203,0.8);
  --ylr-color-primary2-border: #00BBF3;
  --ylr-color-primary2-hover: rgba(0, 138, 180, 0.8);
  --ylr-color-success: #00b578;
  --ylr-color-warning: #ff8f1f;
  --ylr-color-danger: rgba(255,64,0,0.8);
  --ylr-color-danger-border: #FF2800;
  --ylr-color-danger-hover: rgba(238, 61, 1, 0.8);
  --ylr-color-border: #2C6199;
  --ylr-color-link: #0081FF;
  --ylr-color-white: #fff;
  --ylr-color-text: #333;
  --ylr-color-text-secondary: #666;
  --ylr-color-weak: #999;
  --ylr-color-light: #ccc;
  --ylr-color-scroll: #396D9F;
  --ylr-color-bg-title: #1073B9;
  --ylr-color-bg-content: #03243F;
  --ylr-color-bg-footer: #133461;
  --ylr-color-bg-avatar-start: #0099FF;
  --ylr-color-bg-avatar-end: #006FFF;
  // --ylr-color-border: #eee;
  // --ylr-color-box: #f5f5f5;
  // --ylr-color-background: #fff;
  
  /* 图标 */
  .ico.ico-record-audio {cursor: default;width: 15px;height: 12px;background-image: url(./image/rtc/darkblue/ico-record-audio.svg);}
  .ico.ico-record-video {width: 15px;height: 12px;background-image: url(./image/rtc/darkblue/ico-record-video.svg);}

  /* 输入框 */
  .commonInput{outline: none;padding-left: 5px;width: 190px;height: 32px;background: #02234B;border-radius: 3px;border: 1px solid rgba(0,200,255,0.6);font-size: var(--ylr-font-size);}

  /* 按钮 */
  .commonBtn{min-width:72px;text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);font-family: PingFangSC-Semibold, PingFang SC, sans-serif;font-weight: 600;color: var(--ylr-color-white);line-height: 20px;border-radius: 2px;}
  .commonBtn.ant-btn-default{background: var(--ylr-color-default);border: 1px solid var(--ylr-color-default-border);color: var(--ylr-color-white);}
  
  /* 页卡 */
  .commonTabs.ant-tabs .ant-tabs-ink-bar{background:#59AEEA;height:1px!important;}
  .commonTabs.ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab{border-radius:0;border:2px solid #1073B9;background:none;color:var(--ylr-color-white);width:36px;padding: 79px 0 80px 1px;line-height: 22px;font-size: 16px;font-weight: 600;margin:0!important;height: 224px;}
  .commonTabs.ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab-active{background:#1073B9;border-color:transparent;}
  
  /* 表单 */
  .commonForm .ant-form-item-label > label {color: #B2E2FF;}
  .commonForm .ant-input {background: #02234B!important;border: 1px solid rgba(0,200,255,0.6);color: var(--ylr-color-white);}
  
  /* 列表 */
  .commonList .ant-list-item {border-bottom: 1px solid #08599a!important;}

  /* 表格 */
  
  /* 树 */
  .commonTree.ant-tree .ant-tree-checkbox-inner {background:none;border: 1px solid rgba(39, 125, 237, 0.7)!important;}
  .commonTree.ant-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner,
  .commonTree.ant-tree .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {background:#3E92FF;}

  // 滚动条样式

  /* 发光边框 */
  .commonBorder {
      border: 1px solid rgba(0, 136, 255, 0.4);box-shadow: 0 0 6px rgba(0, 136, 255, 0.7) inset;
  }

  /* 地图信息 */
  
  /* 对话框 */
  .commonDialog .ant-modal-content {background:linear-gradient(to bottom, rgba(0, 63, 128, 0.9) 0%,rgba(13, 113, 184, 0.95) 100%);border:1px solid rgba(173, 211, 255, 0.5);border-radius:0;padding: 0;}

  /* 视频播放 */

  /* 动画 */
}
