:global {
    li {list-style:none;}
    .main_con{margin:20px auto;width:700px;text-align:left;}
    .api > ul{margin:0 0 60px;padding:4px 8px 10px;background: #2d2d2d;color: #fff;}
    .commonThemeWhite .api > ul{background: #fff;color: #2d2d2d;}
    .api > ul > li{margin:10px 0;}
    .api > ul > li:after{content:'';display:block;clear:both;}
    .api > ul > li{zoom:1;}
    .api > ul > li.cont{padding:0 0 0 20px;}
    .apiMenu{position:fixed;right:10px;top:10px;border:1px solid #ececec;opacity:0.95;z-index:999;}
    .apiMenu li{border-top:1px solid #ececec;text-align:center;}
    .apiMenu li:first-child{border:none;}
    .apiMenu li a{display:block;padding:8px 20px;color:#333;background:#fff;}
    .apiMenu li a:hover{background:#ececec;}
    .apiMenu li.active a{background:#0433fe;color:#fff;}
}
