@import './prism.less';

body > iframe {
  display: none;
}

.app {
  text-align: center;
  padding: 10px 0;
  overflow: auto;
  button,select {
    // height: 30px;
    // background: rgba(0, 0, 0, 0.2);
    // border: 1px solid #333;
    margin: 5px 0 0 10px;
    // cursor: pointer;
  }
  h3{margin:20px 0;}
  .tabs{
    width: 300px;margin: 0 auto 30px;
  }
  .link {
    text-align: right;
    max-width: 900px;
    margin: -10px auto 3px;
    a {margin:0 20px 0 0;}
  }
  .code{
    max-width: 900px;height: 300px;overflow: auto;margin: 0 auto 30px;
    pre {margin: 0;}
  }
  .dropList{display: inline-block;}
  .dropList .dropListCont{position: absolute;z-index:49;display:none;padding:10px;margin: 0px 0 0 10px;text-align: left;min-width:200px;background-color: #fff;border-radius: 2px;outline: none;box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);}
  .dropList:hover .dropListCont{display: block;}
  .dropList .dropListCont li{list-style: none;line-height: 30px;}
  .dropList .dropListCont li button{margin:0 10px 0 0;}
  .info{margin: 0 0 0 10px;display: inline-block;}
}

.videos {
  display: flex;
  margin-top: 20px;
  align-items: center;
  flex-direction: column;
  
  audio {
    margin: 10px 0;
  }
  video {
    border: 1px solid #d9d9d9;
    margin: -1px 20px 0;
  }
  span {
    position: relative;
    top: 10px;
    background: #f8f8f8;
    padding: 0 10px;
  }
}
