
// 邀请会议、人员调度、添加视频
.invite {
  // height: 456px;
  padding: 4px;
  background: rgba(3,36,63,0.65);
  margin-bottom: -10px;
  display: flex;
  flex-direction: row;
  :global {
    .ant-tabs {
      flex: 1;
    }
    .ant-tree {
      flex: 1;
      overflow: auto;
    }
    .ant-checkbox-wrapper {
      color: var(--ylr-color-white);
    }
    .ant-empty-description {
      color: var(--ylr-color-white);
    }
  }
  .cont {
    margin: 10px 6px 10px 10px;
    height: 428px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .title {
    color: var(--ylr-color-white);
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 10px;
    display: flex;
    em {
      float: right;
      font-style: normal;
      font-size: 14px;
      margin-top: 2px;
    }
    input {
      margin-right: 10px;
      // width: 175px;
      flex: 1;
    }
  }
  .footer {
    background: rgba(16,98,159,0.5);
    color: #95A0B8;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    margin-top: 10px;
  }
  .selected {
    width: 340px;
    margin: 10px 10px 10px 0;
    padding: 0 0 0 10px;
    border-left: 1px solid var(--ylr-color-border);
    display: flex;
    flex-direction: column;
    .cont {
      padding: 0;
      margin: 0;
      color: var(--ylr-color-white);
      font-size: 14px;
      line-height: 20px;
      list-style: none;
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 393px;
      :global {
        .ant-empty {
          margin-top: 140px;
        }
      }
      li {
        margin: 0 0 10px;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
          width: 205px;
        }
        em {
          float: right;
          margin: 3px 3px 0 0;
        }
      }
      .playerWrap {
        width: 100%;
        height: 100%;
      }
      .player {
        background: #000;
        border-radius: 3px;
        border: 1px solid rgba(0,200,255,0.6);
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden;
        video {
          height: 100%;
          width: 100%;
          object-fit: contain!important;
          position: absolute;
        }
        :global {
          .ant-spin {
            top: 45%;
            left: 45%;
            position: absolute;
            z-index: 9;
          }
        }
      }
    }
    .btn {
      margin: 10px 0 20px;
      text-align: right;
    }
    :global{
      .commonForm {
        // height: 174px;
      }
      // 发送时间错误提示
      .ant-form.isSendTime {
        .ant-radio-group{
          position: relative;
          z-index: 9;
        }
        .ant-form-item:last-child .checkboxWrap .ant-form-item-explain-error {
          position: absolute;
          margin: 37px 0 0 0;
        }
      }
    }
  }
}