// @import './common.module.less';
// @import './common.cyan.module.less';
// @import './common.blue.module.less';
// @import './common.darkblue.module.less';
// @import './common.white.module.less';

// 转盘
.turnplate {
  position: absolute;
  bottom: 40px;
  right: 40px;
  background: url(../../assets/image/rtc/turnplate-0.svg) no-repeat;
  background-size: 278px 278px;
  background-position: 11px 11px;
  background-repeat: no-repeat;
  width: 300px;
  height: 300px;
  border-radius: 300px;
  overflow: hidden;
  color: var(--ylr-color-white);
  display: flex;
  flex-wrap: wrap;
  &:after {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    background: url(../../assets/image/rtc/turnplate.svg) no-repeat;
    background-size: contain;
    width: 300px;
    height: 300px;
    border-radius: 300px;
    animation: rotate 4000ms linear infinite;
    @keyframes rotate {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }
  > div {
    cursor: pointer;
    width: 100px;
    height: 100px;
    position: absolute;
    z-index: 1;
    > div {
      text-shadow: 0px 2px 8px #0055A1;
      // background: linear-gradient(180deg, #FFFFFF 0%, #C3E6FF 100%);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
      flex: 1;
      width: 100%;
      height: 100%;
    }
    em {
      content: '';
      display: block;
      margin: 34px auto -4px;
    }
  }
  .btnCircle0 {
    position: absolute;
    width: 75px;
    height: 75px;
    top: 113px;
    left: 113px;
    em {
      margin: 16px auto -4px;
    }
  }
  .btnCircle1 {
    top: -8px;
    left: 97px;
  }
  .btnCircle2 {
    top: 63px;
    left: 195px;
  }
  .btnCircle3 {
    top: 167px;
    left: 156px;
  }
  .btnCircle4 {
    top: 173px;
    left: 42px;
  }
  .btnCircle5 {
    top: 62px;
    left: 5px;
  }
}
.turnplate.active1 {
  background-image: url(../../assets/image/rtc/turnplate-1.svg);
  background-size: 278px 278px;
  background-position: 11px 11px;
  background-repeat: no-repeat;
}
.turnplate.active2 {
  background-image: url(../../assets/image/rtc/turnplate-2.svg);
  background-size: 278px 278px;
  background-position: 11px 11px;
  background-repeat: no-repeat;
}
.turnplate.active3 {
  background-image: url(../../assets/image/rtc/turnplate-3.svg);
  background-size: 278px 278px;
  background-position: 11px 11px;
  background-repeat: no-repeat;
}
.turnplate.active4 {
  background-image: url(../../assets/image/rtc/turnplate-4.svg);
  background-size: 278px 278px;
  background-position: 11px 11px;
  background-repeat: no-repeat;
}
.turnplate.active5 {
  background-image: url(../../assets/image/rtc/turnplate-5.svg);
  background-size: 278px 278px;
  background-position: 11px 11px;
  background-repeat: no-repeat;
}
.turnplate.active6 {
  background-image: url(../../assets/image/rtc/turnplate-6.svg);
  background-size: 278px 278px;
  background-position: 11px 11px;
  background-repeat: no-repeat;
}

// 来电
.turnplateRingContainer {
  color: var(--ylr-color-white);
  border-radius: 50%;
  text-align: center;
  .defaultCallPhone {
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-family: DINAlternate-Bold, DINAlternate, sans-serif;
    font-weight: bold;
    color: var(--ylr-color-white);
    line-height: 46px;
    margin: -7px 40px 36px;
    overflow: hidden;
    > span {
      max-width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    > em {
      position: absolute;
      display: block;
      margin: 52px 0 0 0;
      font-weight: 400;
      font-size: var(--ylr-font-size);
      font-style: normal;
      opacity: 0.7;
    }
  }
  > div {
    display: inline-block;
    cursor: pointer;
    > i {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      display: block;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      margin-bottom: 12px;
    }
    &.acceptRingBtn {
      margin-left: 64px;
      > i {
        box-shadow: 0 0 20px rgb(103 255 93);
        transition: box-shadow 1s;
        animation: shake 800ms ease-in-out;
        &.isVideo {
          box-shadow: 0 0 20px rgb(133, 159, 255);
        }
        &.isNormal {
         box-shadow: none;
         animation: none;
        }
        @keyframes shake {
          10%, 90% { transform: translate3d(-1px, 0, 0); }
          20%, 80% { transform: translate3d(+2px, 0, 0); }
          30%, 70% { transform: translate3d(-4px, 0, 0); }
          40%, 60% { transform: translate3d(+4px, 0, 0); }
          50% { transform: translate3d(-4px, 0, 0); }
        }
      }
    }
    // &.rejectRingBtn {
    //   > i {
    //     background-image: var(--ylr-ico-call-btn-hangup);
    //   }
    // }
  }
}

// 会议成员列表
.member {
  display: flex;
  height: calc(100% - 10px);
  min-height: 514px;
  &.isCall {
    height: auto;
    min-height: auto;
    // margin-top: -3px;
  }
  .video {
    flex: 1;
    position: relative;
    text-align: center;
    > span {
      display: inline-block;
      text-align: center;
      color: var(--ylr-color-white);
      width: 100px;
      margin: 0 -9px 0;
      // padding: 0 20px;
      font-size: calc(var(--ylr-font-size) - 2px);
      cursor: pointer;
      position: relative;
      top: 7px;
      em {
        display: block;
        margin: 0 auto 4px;
      }
    }
    .iframe {
      line-height: 0;
      height: calc(100% - 51px);
      position: relative;
      box-sizing: content-box;
      :global {
        .ant-row {
          margin: 0!important;
          height: 100%;
          background: #000;
        }
        .ant-avatar {
          font-size: 29px!important;
          z-index: 9;
          top: calc(50% - 40px);
          left: calc(50% - 40px);
          position: absolute;
          background: linear-gradient(to bottom, var(--ylr-color-bg-avatar-start) 0%, var(--ylr-color-bg-avatar-end) 100%);
          background-size: 80px 80px;
          background-position: 0 -1px;
        }
        .ant-col {
          // margin: 0 auto;
          border: 2px solid var(--ylr-color-border);
        }
      }
      .name {
        position: absolute;
        background: rgba(142, 142, 142, 0.6);
        color: #fff;
        padding: 0 10px;
        bottom: 5px;
        left: 5px;
        z-index: 9;
        line-height: 30px;
        font-weight: bold;
        em {
          width: 14px;
          height: 14px;
          margin: 0 4px 0 0;
          background-size: contain!important;
          cursor: pointer;
          display: inline-block;
          vertical-align: middle;
          i {
            display: inline-block;
            width: 14px;
            height: 14px;
            margin: 0 0 5px;
          }
        }
      }
      video {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        background: #000;
      }
      .loading {
        position: absolute;
        top: 4px;
        left: 4px;
        right: 4px;
        bottom: 4px;
        background: var(--ylr-color-bg-content);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      iframe {
        border: 0;
        width: 100%;
        height: 100%;
        min-height: 454px;
        margin: 0;
        visibility: hidden;
        position: relative;
        z-index: 9;
      }
    }
    .right {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .left {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .list {
    width: 200px;
    background: var(--ylr-color-bg-content);
    color: var(--ylr-color-white);
    border-radius: 3px;
    float: right;
    margin: 0 0 0 8px;
    display: flex;
    flex-direction: column;
    :last-child {
      overflow: auto;
    }
    .title {
      font-size: var(--ylr-font-size);
      border-radius: 3px 3px 0 0;
      background: var(--ylr-color-bg-title);
      // height: 23px;
      // line-height: 23px;
      padding: 3px 10px;
      span {
        font-weight: bold;
      }
    }
    .footer {
      background: var(--ylr-color-bg-footer);
      // height: 28px;
      // line-height: 28px;
      border-radius: 0 0 3px 3px;
      padding: 6px 0;
      text-align: center;
      font-size: calc(var(--ylr-font-size) - 2px);
      :global {
        .ant-divider{border-color:rgba(255,255,255,0.4)!important;}
      }
      > span {
        cursor: pointer;
        &:before {
          content: '';
          width: var(--ylr-font-size);
          height: calc(var(--ylr-font-size) + 1px);
          display: inline-block;
          vertical-align: middle;
          margin: -2px 4px 0 0;
          background: url(../../assets/image/rtc/call-btn-invite.svg) no-repeat 50% 50%;
          background-size: contain;
        }
      }
      .btnInvite {
        &::before {
          
        }
      }
      .btnMute {
        &::before {
          margin-top: -4px;
          width: calc(var(--ylr-font-size) - 3px);
          vertical-align: middle;
          background-image: url(../../assets/image/rtc/call-btn-mute.svg);
        }
      }
    }
    :global {
      .ant-empty-description {
        color: var(--ylr-color-white);
      }
      .ant-list {
        flex: 1;
      }
    }
    li {
      justify-content: flex-start;
      color: var(--ylr-color-white);
      border: none;
      padding: 0;
      margin: 8px 16px 0;
      overflow: hidden!important;
      span {
        cursor: pointer;
        max-width: 68px;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
      }
      span:hover {
        color: #1890ff;
      }
      em {
        width: 14px;
        height: 14px;
        margin: -3px 4px 0;
        cursor: pointer;
        overflow: visible!important;
        i {
          background-size: contain!important;
          display: inline-block;
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  .btnVideo {
    i {
      background: url(../../assets/image/rtc/call-btn-video-open-small.svg) no-repeat;
      background-repeat: no-repeat;
    }
    &.active {
      i {
        background: url(../../assets/image/rtc/call-btn-video-close-small.svg) no-repeat;
        background-repeat: no-repeat;
      }
    }
  }
  .btnAudio {
    i {
      width: 11px;
      margin: 0 0 0 2px;
      background: url(../../assets/image/rtc/call-btn-unmute-small.svg) no-repeat;
      background-repeat: no-repeat;
    }
    &.active {
      i {
        width: 14px;
        margin: 0;
        background: url(../../assets/image/rtc/call-btn-mute-small.svg) no-repeat;
        background-repeat: no-repeat;
      }
    }
  }
  .btnHangup {
    i {
      background: url(../../assets/image/rtc/call-btn-hangup-small.svg) no-repeat;
      background-repeat: no-repeat;
    }
  }
  .btnCall {
    i {
      background: url(../../assets/image/rtc/call-btn-call-small.svg) no-repeat;
      background-repeat: no-repeat;
    }
  }

  // 浅色主题
  &.commonThemeWhite {
    .video {
      > span {
        color: #4C84FF;
      }
      .iframe {
        :global {
          .ant-col {
            border-color: #fff;
          }
        }
      }
    }
    .list {
      background: rgba(211,217,226,0.15);
      border-radius: 8px;
      border: 1px solid #D3D9E2;
      .title {
        background: none;
      }
      .footer {
        background: none;
        border-top: 1px solid #D3D9E2;
        color: #4C84FF;
        > span:before {
          transform: translateY(-32px);
          filter: drop-shadow(0 32px 0 #4C84FF); 
        }
        :global {
          .ant-divider{border-color:#E6EAEF!important;height:20px;}
        }
      }
    }
    .btnAudio,.btnVideo,.btnCall {
      i {
        transform: translateY(-32px);
        filter: drop-shadow(0 32px 0 #4C84FF);
      }
      &.active {
        i {
          transform: unset;
          filter: none;
        }
      }
    }
  }
}
// 最大化或最小化
.max {
  color: var(--ylr-color-primary-border);
  position: absolute;
  right: 30px;
  top: 5px;
  z-index: 1500;
  overflow: hidden;
  font-size: var(--ylr-font-size);
  em {
    display: inline-block;
    width: 32px;
    height: 32px;
    text-align: center;
    margin: -3px 5px 0 8px;
    cursor: pointer;
    :global {
      .ico-min {
        margin-top: 8px;
      }
    }
  }
  // 浅色主题
  &.commonThemeWhite {
    color: var(--ylr-color-white);
  }
}
// 展开收起
.fold {
  position: absolute;
  z-index: 10;
  top: 49%;
  right: var(--ylr-space);
  width: 20px;
  height: 51px;
  line-height: 14px;
  background: var(--ylr-color-bg-title);
  color: var(--ylr-color-white);
  border-radius: 3px 0 0 3px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  em {
    display: block;
    margin: 6px auto 4px;
    width: 9px;
    height: 9px;
    background: url(../../assets/image/rtc/call-btn-fold.svg) no-repeat 50% 50%;
    background-size: contain;
    &.isUnfold {
      background-image: url(../../assets/image/rtc/call-btn-unfold.svg);
    }
  }
  // 浅色主题
  &.commonThemeWhite {
    background: rgb(249 250 251);
    border-radius: 2px;
    border: 1px solid #D3D9E2;
    overflow: hidden;
    em {
      transform: translateY(-32px);
      filter: drop-shadow(0 32px 0 #4A5B86); 
    }
  }
}

// 拨号
.callModal {
  .defaultCallPhone {
    height: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    font-family: DINAlternate-Bold, DINAlternate, sans-serif;
    font-weight: bold;
    color: var(--ylr-color-white);
    line-height: 46px;
    margin: 0 40px 43px;
    overflow: hidden;
    position: relative;
    z-index: 9;
    > span {
      max-width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    > em {
      position: absolute;
      display: block;
      margin: 57px 0 0 0;
      font-weight: 400;
      font-size: var(--ylr-font-size);
      font-style: normal;
      opacity: 0.7;
    }
  }
  // 拨号
  .callDefaultContainer {
    .callBox {
      width: 228px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      .callBtn {
        width: 49px;
        height: 49px;
        border-radius: 49px;
        overflow: hidden;
        // background: var(--ylr-ico-call-btn) no-repeat;
        // background-size: 100% 100%;
        margin: 0 13px 14px 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-family: DINAlternate-Bold, DINAlternate, sans-serif;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
        user-select: none;
        text-shadow: 0px 2px 6px rgba(1,64,129,0.7600);
        &:nth-child(10) {
          padding-top: 10px;
          box-sizing: border-box;
        }
      }
      // .callBtn.on {
      //   background-image: var(--ylr-ico-call-btn-on);
      // }
    }
    .callFunBox {
      display: flex;
      justify-content: space-between;
      padding: 12px 0 34px;
      width: 200px;
      margin: 0 auto;
      // > span {
      //   width: 41px;
      //   height: 41px;
      // }
      .callBtnCall {
        cursor: pointer;
        border-radius: 49px;
        margin: 0 0 0 -6px;
      }
      .callBtnCallVideo {
        cursor: pointer;
        border-radius: 49px;
      }
      .callBtnDelete {
        margin: 14px 10px 0 0;
        position: relative;
        // background: var(--ylr-ico-call-btn-del) no-repeat;
        // background-size: 32px 22px;
        // background-position: center;
        // background-repeat: no-repeat;
        // cursor: pointer;
      }
      // .callBtnDelete.on {
      //   background-image: var(--ylr-ico-call-btn-del-on);
      // }
    }
  }
  // 通话
  .callContainer {
    .callStatus {
      margin: 29px 22px 0;
      height: 67px;
      .callingContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > canvas {
            width: 278px;
            height: 44px;
            margin-top: 16px;
        }
        // > i {
        //   width: 278px;
        //   height: 44px;
        //   margin-top: 16px;
        //   // background: url(../../assets/image/rtc/call-status.png) no-repeat;
        //   // background-size: 100% 100%;
        //   background-image: url(../../assets/image/rtc/call-status-animate.png);
        //   animation: call-status 2s steps(50) infinite;
        //   background-size: 278px 2200px;
        // }
        > span {
          font-weight: 500;
          color: var(--ylr-color-primary-border);
        }
      }
      .onlineContent {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 40px;
        position: relative;
        .callName {
          margin-top: 18px;
          height: 45px;
          font-size: 32px;
          font-weight: 400;
          color: var(--ylr-color-white);
          line-height: 45px;
          width: 100%;
          white-space: nowrap;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .callVideoPlayer {
          position: absolute;
          background: #000;
          top: -154px;
          left: -22px;
          right: -22px;
          bottom: -280px;
          :global {
            .ant-spin {
              position: absolute;
              top: 48%;
              left: 48%;
              z-index: 9;
            }
          }
          video {
            width: 100%;
            height: 100%;
            background: #000;
          }
          .isSmall,.change {
            position: absolute;
            width: 210px;
            height: 115px;
            top: 0;
            right: 0;
            z-index: 9;
            border: 1px solid #ffffff80;
          }
          audio {
            margin: 24.5% 0 0 32%;
          }
          .change {
            cursor: pointer;
          }
        }
      }
    }
    .callTime {
      margin: 26px 40px 0;
      text-align: center;
      height: 25px;
      font-size: 18px;
      font-weight: 500;
      color: var(--ylr-color-white);
      line-height: 25px;
      position: relative;
    }
    .callFunBox {
      margin-top: 37px;
      padding: 0 40px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: relative;
      > span {
        width: 80px;
        height: 76px;
        font-size: var(--ylr-font-size);
        font-weight: 500;
        color: var(--ylr-color-white);
        line-height: 25px;
        display: flex;
        text-align: center;
        flex-direction: column;
        cursor: pointer;
        > i {
          width: 40px;
          height: 40px;
          margin: 0 auto 11px;
          background-position: center;
          background-repeat: no-repeat;
        }
        > label {
          height: 25px;
          position: relative;
          top: -20px;
          cursor: pointer;
        }
      }
      .callFunMuteOn i {
        background-image: url(../../assets/image/rtc/call-btn-unmute.svg);
      }
      .callFunMuteOff i {
        background-image: url(../../assets/image/rtc/call-btn-mute.svg);
      }
    }
    .callHangup {
      width: 49px;
      height: 49px;
      margin: 32px auto 35px;
      position: relative;
      z-index: 11;
    }
    .callToVideo {
      right: -73px;
      bottom: 15px;
      z-index: 20;
      position: absolute;
    }
    .callInvite {
      left: -73px;
      bottom: 15px;
      z-index: 20;
      position: absolute;
    }
    .callLeftBtn {
      position: absolute;
      left: var(--ylr-space);
      right: var(--ylr-space);
      bottom: var(--ylr-space);
    }
    &.isVideoBtnBottom {
      .callTime, .callFunBox, .callHangup, .callLeftBtn {
        visibility: hidden;
      }
    }
  }
  &.isPlayer {
    width: 100%;
    height: 502px;
    .callContainer {
      .callTime, .callFunBox, .callHangup {
        display: none;
      }
    }
  }
  // 浅色主题
  &.commonThemeWhite {
    .callDefaultContainer .callBox .callBtn{
      text-shadow: none;
      color: var(--ylr-color-white);
    }
    .callContainer {
      .callStatus .callingContent > span {
        color: var(--ylr-color-white);
      }
    }
    .member .video > span {
      color: var(--ylr-color-link);
      :global {
        .ico-call-invite {
          transform: translateY(9999px);
          filter: drop-shadow(0 -9999px 0 var(--ylr-color-link));
        }
      }
    }
    &.isCallVideo {
      .defaultCallPhone {
        color: #fff;
      }
    }
  }
}

// 未接来电
.callMissModal {
  // height: 268px;
  height: 192px;
  overflow: auto;
  :global {
    .ant-list {
      margin: -10px 10px -7px;
      overflow: visible;
    }
    .ant-list-item-meta-title {
      margin: 0 0 -2px!important;
      cursor: default!important;
    }
    .ico {
      margin: 4px 0 0 4px;
    }
    .ico.ico-call-audio, .ico.ico-call-video {
      margin: 0 0 0 8px;
      width: 34px;
      height: 34px;
    }
    .ant-empty {
      padding: 25px 0 0;
      .ant-empty-description {
        color: var(--ylr-color-white);
      }
    }
    .ant-list-item:last-child {
      border-bottom: 1px solid var(--ylr-color-border) !important;
    }
    .ant-list-item:last-child:not(:first-child) {
      border-bottom: none !important;
    }
  }
}
.callMissIgnoreAll {
  margin: 0 10px 0 0;
  cursor: pointer;
  > i {
    margin: -2px 4px 0 0;
  }
}

// 拨号最小化
.minCallModal {
  cursor: pointer;
  width: 106px;
  height: 106px;
  position: absolute;
  .time {
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-shadow: 0px 2px 4px rgba(0,0,0,0.5), 0px 1px 1px rgba(0,0,0,0.3);
    &.text {
      font-size: 15px;
    }
  }
  :global {
    .ant-badge .ant-badge-count {background:#ff4d4f;box-shadow:0 0 0 1px #fff;color:#fff;}
  }
}

// 通讯录
.addressBookContainer {
  position: relative;
  height: 610px;
  padding: 0 8px;
  overflow: hidden;
  background: rgba(3,36,63,0.65);

  .itemTitle {
    position: relative;
    top: 2px;
    left: 25px;
    width: 150px;
    height: 31px;
    font-size: 24px;
    font-family: YouSheBiaoTiHei, sans-serif;
    color: #d7f5ff;
    line-height: 31px;
    background: linear-gradient(180deg, #fff 0%, #88b2ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .searchWrapper {
    margin: 10px 0;
    display: flex;
    .inputEl {
      margin-right: 10px;
      color: #BFCEDF;
      padding-left: 10px;
      outline: none;
      font-size: var(--ylr-font-size);
      flex: 1;
      height: 30px;
      background: var(--ylr-color-default);
      border-radius: 3px;
      border: 1px solid var(--ylr-color-default-border);
    }
    input::-webkit-input-placeholder {
      color: #5982A5;
    }
    input::-ms-input-placeholder {
      color: #5982A5;
    }
  }

  .treeWrapper {
    // margin: 100px 5px 0 15px;
    .antTree {
      background: transparent;
      .parentTitle {
        font-weight: 550;
      }
      .treeIcon {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url('../../assets/image/rtc/addressBook.png') no-repeat center;
        background-size: 100% 100%;
      }
      .treeTitle {
        .treeTitleName {
          display: inline-block;
          width: 215px;
          overflow:hidden;
          text-overflow:ellipsis;
          white-space:nowrap;
          vertical-align: middle;
        }
        .treeTitleNum {
          display: inline-block;
          width: 110px;
          color: #3FB7FF;
        }
        .treeTitleIcon {
          display: inline-block;
          width: 100px;
          em {
            margin: 0 10px 0 0;
          }
        }
      }
    }
  }
}
.quickMeetBtn {
  margin: 20px auto 10px;
}