// 拨号
.callModal {
  position: fixed;
  background: rgb(68, 68, 68);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  .callModalMask {
    height: 100%;
  }
  .defaultCallPhone {
    // height: 112px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-family: DINAlternate-Bold, DINAlternate, sans-serif;
    font-weight: bold;
    color: var(--ylr-color-white);
    line-height: 46px;
    margin: 0 40px 43px;
    overflow: hidden;
    position: relative;
    z-index: 9;
    > span {
      max-width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    > em {
      position: absolute;
      display: block;
      margin: 57px 0 0 0;
      font-weight: 400;
      font-size: 18px;
      font-style: normal;
      opacity: 0.7;
    }
  }
  .callDefaultContainer {
    width: 302px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    .callBox {
      padding: 0 40px;
      display: flex;
      flex-wrap: wrap;
      margin-right: -27px;
      .callBtn {
        width: 56px;
        height: 56px;
        border-radius: 56px;
        overflow: hidden;
        // background: var(--ylr-ico-call-btn) no-repeat;
        // background-size: 100% 100%;
        margin-bottom: 14px;
        margin-right: 27px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-family: DINAlternate-Bold, DINAlternate, sans-serif;
        font-weight: bold;
        color: var(--ylr-color-white);
        cursor: pointer;
        user-select: none;
        text-shadow: 0px 2px 6px rgba(1,64,129,0.7600);
        &:nth-child(10) {
          padding-top: 10px;
          box-sizing: border-box;
        }
      }
      // .callBtn.on {
      //   background-image: var(--ylr-ico-call-btn-on);
      // }
    }
    .callFunBox {
      display: flex;
      justify-content: space-between;
      padding: 12px 40px 34px;
      > span {
        width: 56px;
        height: 56px;
      }
      .callBtnCall {
        cursor: pointer;
        border-radius: 56px;
      }
      .callBtnCallVideo {
        cursor: pointer;
        border-radius: 56px;
      }
      .callBtnDelete {
        width: 43px;
        height: 30px;
        margin: 10px 13px 0 0;
        position: relative;
        right: -5px;
        // background: var(--ylr-ico-call-btn-del) no-repeat;
        // background-size: 32px 22px;
        // background-position: center;
        // background-repeat: no-repeat;
        // cursor: pointer;
      }
      // .callBtnDelete.on {
      //   background-image: var(--ylr-ico-call-btn-del-on);
      // }
    }
  }
  .callContainer {
    // margin: 30% 0 0;
    display: flex;
    height: 100%;
    flex-direction: column;
    .defaultCallPhone {
      margin-bottom: 74px;
    }
    .callStatus {
      margin: 29px 22px 0;
      height: 67px;
      .callingContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        > canvas {
            width: 278px;
            height: 44px;
            margin-top: 16px;
        }
        // > i {
        //   width: 278px;
        //   height: 44px;
        //   margin-top: 16px;
        //   // background: url(../../../assets/image/rtc/call-status.png) no-repeat;
        //   // background-size: 100% 100%;
        //   background-image: url(../../../assets/image/rtc/call-status-animate.png);
        //   animation: call-status 2s steps(50) infinite;
        //   background-size: 278px 2200px;
        // }
        > span {
          font-weight: 500;
          color: #B2E6F3;
        }
      }
      .onlineContent {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 40px;
        position: relative;
        .callName {
          margin-top: 18px;
          height: 45px;
          font-size: 32px;
          font-weight: 400;
          color: var(--ylr-color-white);
          line-height: 45px;
          width: 100%;
          white-space: nowrap;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .callVideoPlayer {
          position: fixed;
          background: #000;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          :global {
            .ant-spin {
              position: absolute;
              top: 48%;
              left: 48%;
              z-index: 9;
            }
          }
          video {
            width: 100%;
            height: 100%;
            background: #000;
          }
          audio {
            margin: 24.5% 0 0 32%;
          }
        }
      }
    }
    .isSmall,.change {
      position: absolute;
      width: 80px!important;
      height: 115px!important;
      top: 12px;
      right: 12px;
      z-index: 9;
      border: 1px solid #ffffff80;
    }
    .change {
      cursor: pointer;
    }
    .callTime {
      margin: 26px 40px 0;
      text-align: center;
      height: 25px;
      font-size: 20px;
      font-weight: 500;
      color: var(--ylr-color-white);
      line-height: 25px;
      position: relative;
    }
    .callFunBox {
      margin-top: 37px;
      padding: 0 40px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: relative;
      > span {
        width: 80px;
        height: 76px;
        font-size: 14px;
        font-weight: 500;
        color: var(--ylr-color-white);
        line-height: 25px;
        display: flex;
        text-align: center;
        flex-direction: column;
        cursor: pointer;
        > i {
          width: 40px;
          height: 40px;
          margin: 0 auto 11px;
          background-position: center;
          background-repeat: no-repeat;
        }
        > label {
          height: 25px;
          position: relative;
          top: -20px;
          cursor: pointer;
        }
      }
      .callFunMuteOn i {
        background-image: url(../../../assets/image/rtc/call-btn-unmute.svg);
      }
      .callFunMuteOff i {
        background-image: url(../../../assets/image/rtc/call-btn-mute.svg);
      }
    }
    .callHangup {
      > em {
        width: 56px;
        height: 56px;
      }
      margin: 0 auto 35px;
      position: relative;
      z-index: 11;
    }
    .callAnswer {
      // width: 56px;
      // height: 56px;
      margin: 0 auto 35px;
      // background: var(--ylr-ico-call-btn-audio) no-repeat 50% 50%;
      // background-size: contain;
      // cursor: pointer;
      position: relative;
      z-index: 11;
    }
    .callToVideo {
      right: -53px;
      bottom: 12px;
      z-index: 20;
      position: absolute;
    }
    .callLeftBtn {
      position: absolute;
      left: 9px;
      right: 9px;
      bottom: 9px;
    }
  }
  &.isPlayer {
    width: 818px;
    .callContainer {
      .callTime, .callFunBox, .callHangup {
        display: none;
      }
    }
  }
}

// 提示浮层
.tips{
	position: fixed;
	left: 0;
  right: 0;
	top: 0;
  bottom: 0;
	z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  .cont {
    background: #000;
    opacity: 0.8;
    border-radius: 7px;
    color: #FFF;
    text-align: center;
    max-width: 80%;
    padding: 10px;
    font-size: 12px;
  }
}

/* 确认框 */
.tipsConfirm{position:fixed;top:0;left:0;width:100%;height:100%;z-index:999;}
	.tipsConfirm .cont{position:absolute;bottom:0;width:100%;background-color:#fff;}
	.tipsConfirm .cont .text{text-align:center;padding:16px 0;font-size:16px;border-bottom:1px solid #efefef;color:#888;}
	.tipsConfirm .btn{display:block;height:49px;color:#292929;line-height:38px;font-size:16px;border-radius: 0;border: none;}
		.tipsConfirm .btn.btnConfirm{position: relative;}
		.tipsConfirm .btn.top-border{height:58px;border-top:7px solid #f1f0f0;}
		.tipsConfirm .btn:active{background-color:#f8f8f8;}
	.tipsConfirm .bg{width:100%;height:100%;background-color:#000;opacity:0.5;}

/* 警告框 */
.tipsAlert{position:fixed;top:0;left:0;width:100%;height:100%;z-index:999;display: flex;align-items: center;justify-content: center;background: rgba(0, 0, 0, 0.5);}
	.tipsAlert .cont{width:256px;background-color:#fff;border-radius:7px;}
	.tipsAlert .cont .text{text-align:center;padding:16px 10px;font-size:16px;border-bottom:1px solid #e2e2e2;line-height: 2;color: #999;}
	.tipsAlert .cont .text h5{line-height: 25px;font-size:16px;color: #000;}
	.tipsAlert .btn{display:block;width:50%;float:left;height:49px;line-height:49px;font-size:16px;border-radius:0;color:#4083FF;text-align: center;}
	.tipsAlert .btn:active{background-color:#f8f8f8;border-bottom-right-radius:7px;border-bottom-left-radius:7px;}
	.tipsAlert .btn a{display:block;width:100%;height:100%;}
	.tipsAlert .btnCancel{border-right:1px solid #e2e2e2;margin-left:-1px;color: #000;}
	.tipsAlert .btnCancel:active{border-bottom-right-radius:0;}
	.tipsAlert .btnConfirm:active{border-bottom-left-radius:0;}
	.tipsAlert .single{width:100%;}
	.tipsAlert .single:active{border-bottom-left-radius:7px;}
