body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f8f8;
}
#root {
  width: 100%;
  height: 100%;
}

/*
* 滚动条
*/

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  /*滚动条整体部分，其中的属性有width,height,background,border等（就和一个块级元素一样）（位置1）*/
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-button {
  /*滚动条两端的按钮，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置2）*/
  /* background: #74d334; */
  height: 0;
  width: 0;
}

::-webkit-scrollbar-track {
  /*外层轨道，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置3）*/
  background: transparent;
}

::-webkit-scrollbar-track-piece {
  /*内层轨道，滚动条中间部分（位置4）*/
  background: transparent;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面可以拖动的那部分（位置5）*/
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

::-webkit-scrollbar-corner {
  /*边角（位置6）*/
  background: transparent;
}

::-webkit-scrollbar-resizer {
  /*定义右下角拖动块的样式（位置7）*/
  background: transparent;
}

.ant-btn > .anticon + span, .ant-btn > span + .anticon{margin-left: 2px;}
.ant-divider-horizontal{margin: 12px 0;}