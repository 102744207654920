.commonThemeWhite {
  --ylr-color-default: #FFFFFF;
  --ylr-color-default-border: #D3D9E2;
  --ylr-color-default-hover: #f4f4f4;
  --ylr-color-primary: #4C84FF;
  --ylr-color-primary-border: #4C84FF;
  --ylr-color-primary-hover: #3868d0;
  --ylr-color-primary2: rgba(0,156,203,0.8);
  --ylr-color-primary2-border: #00BBF3;
  --ylr-color-primary2-hover: rgba(0, 138, 180, 0.8);
  --ylr-color-success: #00b578;
  --ylr-color-warning: #ff8f1f;
  --ylr-color-danger: #F66767;
  --ylr-color-danger-border: #F66767;
  --ylr-color-danger-hover: #d65a5a;
  --ylr-color-border: #D3D9E2;
  --ylr-color-link: #4C84FF;
  --ylr-color-white: #4A5B86;
  --ylr-color-text: #333;
  --ylr-color-text-secondary: #666;
  --ylr-color-weak: #999;
  --ylr-color-light: #ccc;
  --ylr-color-scroll: rgba(92,98,104,0.4);
  --ylr-color-bg-title: #8fcdf9;
  --ylr-color-bg-content: #f4f4f4;
  --ylr-color-bg-footer: #94bdf7;
  --ylr-color-bg-avatar-start: #0099FF;
  --ylr-color-bg-avatar-end: #006FFF;
  --ylr-space: 19px;
  --ylr-font-size: 16px;
  
  color: rgba(31,52,103,0.8);
  
  /* 图标 */
  .ico.ico-close {background-image: url(./image/rtc/white/close.svg);}
  .ico.ico-call-audio {width: 49px;height: 49px;background-image: url(./image/rtc/white/call-btn-audio.svg);}
  .ico.ico-call-video {width: 49px;height: 49px;background-image: url(./image/rtc/white/call-btn-video.svg);}
  .ico.ico-call-hangup {width: 49px;height: 49px;background-image: url(./image/rtc/white/call-btn-hangup.svg);}
  .ico.ico-call-btn {width: 49px;height: 49px;background-image: url(./image/rtc/white/call-btn.svg);}
  .ico.ico-call-btn-on {width: 49px;height: 49px;background-image: url(./image/rtc/white/call-btn-on.svg);}
  .ico.ico-call-btn-del {width: 32px;height: 22px;background-image: url(./image/rtc/white/call-btn-del.svg);}
  .ico.ico-call-btn-del-on {width: 32px;height: 22px;background-image: url(./image/rtc/white/call-btn-del-on.svg);}
  .ico.ico-record-audio {cursor: default;width: 15px;height: 12px;background-image: url(./image/rtc/white/ico-record-audio.svg);}
  .ico.ico-record-video {width: 15px;height: 12px;background-image: url(./image/rtc/white/ico-record-video.svg);}
  .ico.ico-call-invite {width: 22px;height: 22px;background-image: url(./image/rtc/white/call-invite.svg);}
  .ico.ico-call-invite2 {width: 22px;height: 22px;background-image: url(./image/rtc/white/call-invite2.svg);}
  .ico.ico-call-mute {width: 14px;height: 20px;background-image: url(./image/rtc/white/call-btn-mute.svg);}
  .ico.ico-call-unmute {width: 18px;height: 20px;background-image: url(./image/rtc/white/call-btn-unmute.svg);}
  .ico.ico-call-video-open {width: 21px;height: 20px;background-image: url(./image/rtc/white/call-btn-video-open.svg);}
  .ico.ico-call-video-close {width: 18px;height: 20px;background-image: url(./image/rtc/white/call-btn-video-close.svg);}
  .ico.ico-call-member {width: 21px;height: 20px;background-image: url(./image/rtc/white/call-btn-member.svg);}
  .ico.ico-call-to-video {width: 24px;height: 17px;background-image: url(./image/rtc/white/call-to-video.svg);}
  .ico.ico-call-to-audio {width: 21px;height: 17px;background-image: url(./image/rtc/white/call-to-audio.svg);}
  .ico.ico-event-edit-done {width: 18px;height: 18px;background-image: url(./image/rtc/white/ico-event-edit-done.svg);}
  .ico.ico-event-link-done {width: 18px;height: 18px;background-image: url(./image/rtc/white/ico-event-link-done.svg);}
  .ico.ico-max {width: 16px;height: 16px;background-image: url(./image/rtc/white/max.svg);}

  /* 输入框 */
  .commonInput{color:#4A5B86;outline: none;padding-left: 5px;width: 190px;height: 32px;background: #fff;border-radius: 2px;border: 1px solid #D3D9E2;font-size: var(--ylr-font-size);box-shadow:none!important;
    &::placeholder{color: #A4ADCE;}
    input::placeholder{color: #A4ADCE;font-size: 16px;font-weight: 500;}
    input{font-size: 16px;border: none;outline:none;color:#4A5B86;}
    .ant-btn{background:none;border:none;color:#D3D9E2!important;box-shadow:unset;padding: 0 10px 0 0;margin: -5px 0 0 0;}
    .ant-input-affix-wrapper{border:none;box-shadow: unset;}
  }

  /* 按钮 */
  .commonBtn{min-width:48px;font-size: 16px;text-shadow: none;box-shadow:none;font-family: PingFangSC-Semibold, PingFang SC, sans-serif;font-weight: 600;color: #fff;line-height: 20px;border-radius: 4px;}
  .commonBtn.ant-btn-default{overflow:hidden;background: #fff;border: 1px solid #D3D9E2;color: #4A5B86;font-weight: 600;
    em {transform: translateY(-32px); filter: drop-shadow(0 32px 0 #4A5B86);}
  }
  .commonBtn.ant-btn-default:hover{background: #fff;border: 1px solid var(--ylr-color-link);color: var(--ylr-color-link);
    em {transform: translateY(-32px); filter: drop-shadow(0 32px 0 var(--ylr-color-link));}
  }
  .commonBtn.ant-btn-primary{background: var(--ylr-color-link);color: #fff;font-weight: 600;}
  .commonBtn.ant-btn-primary:hover{background: #70A5FF;border-color: #70A5FF;}
  .commonBtn.ant-btn-primary:active{background: #3175E9;border-color: #3175E9;}
  .commonBtn.ant-btn.ant-btn-lg{font-size: 18px;}
  
  /* 页卡 */
  .commonTabs.ant-tabs .ant-tabs-ink-bar{background:rgba(74,91,134,0.8);height:1px!important;}
  .commonTabs.ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab{border-radius:0;border:2px solid #1073B9;background:none;color:var(--ylr-color-white);width:36px;padding: 79px 0 80px 1px;line-height: 22px;font-size: 16px;font-weight: 600;margin:0!important;height: 224px;}
  .commonTabs.ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab-active{background:#1073B9;border-color:transparent;}
  
  /* 表单 */
  .commonForm .ant-form-item-label > label {color: rgba(31,52,103,0.8);font-size: 16px;font-weight: 500;}
  .commonForm .ant-select-selector,.commonForm .ant-picker {background: #fff;border: 1px solid #D3D9E2;color: #4A5B86;border-radius: 2px;box-shadow:none!important;width: 100%;}
  .commonForm .ant-select-selector:hover,.commonForm .ant-picker:hover {border-color: #3c89e8;}
  .commonForm .ant-picker .ant-picker-input >input {color: #4A5B86;font-size: 16px;}
  .commonForm .ant-picker .ant-picker-input >input::placeholder {color: #A4ADCE;}
  .commonForm .ant-picker .ant-picker-separator {color: #A4ADCE;}
  .commonForm .ant-picker .ant-picker-clear {background: #fff;color: #BEC9F0;}
  .commonForm .ant-picker .ant-picker-suffix,.commonForm .ant-select .ant-select-arrow {color: #BEC9F0;}
  .commonForm .ant-select {background: #fff;}
  .commonForm .ant-select-selection-item {color: #4A5B86!important;}
  .commonForm .ant-select-dropdown .ant-select-item:hover {background-color: rgba(0, 0, 0, 0.04);}
  .commonForm .ant-select-clear {background: #fff;color: #BEC9F0;}
  .commonForm .ant-select-selection-placeholder {color: #A4ADCE;}
  .commonForm .ant-input {resize: none;font-size: 16px;padding: 2px 11px;background: #fff!important;border: 1px solid #D3D9E2;color: #4A5B86;box-shadow:none!important;
    &::placeholder{color: #A4ADCE;}
    input::placeholder{color: #A4ADCE;font-size: 16px;font-weight: 500;}
    input{font-size: 16px;padding: 2px 11px;}
    &[disabled]{color: rgba(0, 0, 0, 0.25);border-color: #D3D9E2!important;}
    &[readonly] {color: #A4ADCE;}
  }
  .commonForm .ant-input:hover {border-color: #3c89e8;}
  .commonForm .ant-upload-list-item .anticon {color: #BEC9F0!important;}
  // .commonForm textarea.ant-input {margin:7px 0 0 0;}
  .commonForm .ant-input-affix-wrapper {border-radius: 2px;}
  .commonForm.isLarge .ant-input {padding:5px 11px;font-size: 18px;}
  .commonForm.isLarge  .ant-form-item-label > label {padding: 8px 0 0 0;font-size: 18px;}
  .commonForm.isLarge .ant-picker {padding:5px 11px;font-size: 18px;}
  .commonForm.isLarge .ant-picker input {font-size: 18px;}
  .commonForm.isLarge .ant-select-selection-placeholder {font-size: 18px;padding-top: 5px;}
  .commonForm.isLarge .ant-select-selector {height: 40px;}
  .commonForm.isLarge .ant-select-selector .ant-select-selection-item {line-height: 40px;font-size: 18px;}
  .commonForm .ant-form-item {color: #4A5B86;font-size: 16px;margin-bottom: 22px;}
  .commonForm .ant-form-item-control-input-content {font-size: 16px;}
  .commonForm .ant-divider {background: #E6EAEF;}
  
  /* 列表 */
  .commonList .ant-list-item {border-bottom: 1px solid #08599a!important;}

  /* 表格 */
  .commonTable .ant-table-header{border-radius: 0!important;}
  .commonTable .ant-table-thead > tr > * {border-radius: 0!important;border: none!important;;padding: 9px;font-size: 16px;color: rgba(31,52,103,0.8);background: #EFF4FF;}
  .commonTable .ant-table-tbody > tr td {background: #fff;border:none;}
  .commonTable .ant-table-tbody > tr:nth-child(odd) td {background: #F5F9FF;}
  .commonTable .ant-table-tbody > tr > td {font-size: 16px;padding: 8px 9px;}
  .commonTable .ant-table-tbody > tr > td .ant-space {font-size: 16px;}
  .commonTable .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {background: #d9e5fc;}
  .commonTable .ant-pagination-item-active {background: none;border-radius: 4px;border: 1px solid #4C84FF;
    a{color:#4C84FF;}
  }
  .commonTable .ant-table-footer {background: none;}
  .commonTable .ant-table-thead th.ant-table-column-has-sorters:hover {background: none;}
  .commonTable .ant-pagination-total-text {position: absolute;left: 0;}
  .commonTable .ant-pagination {color: #4A5B86;}
  .commonTable .ant-pagination .ant-select {color: #4a5b86;border-color:#D9D9D9;}
  .commonTable .ant-pagination input {background: #fff;color: #4a5b86;border-color:#D9D9D9;}
  .commonTable .ant-pagination .ant-pagination-item {margin:0 0 0 8px;font-size: 16px;font-weight: 400;min-width: 32px;height: 32px;line-height: 30px;border-radius: 4px;border: 1px solid #d3d9e2;}
  .commonTable .ant-pagination .ant-pagination-item a {color: #4a5b86;}
  .commonTable .ant-pagination .ant-pagination-item-active {border: 1px solid #4c84ff;}
  .commonTable .ant-pagination .ant-pagination-item-active a:hover {color: #4c84ff;}
  .commonTable .ant-empty-description {color: #A4ADCE;}
  .commonTable .ant-select-selector {height: 30px;background: #fff;border: 1px solid #D3D9E2;color: #4A5B86!important;border-radius: 2px;box-shadow:none!important;width: 100%;}
  .commonTable .ant-select-selector:hover {border-color: #3c89e8;}
  .commonTable.isSmall .ant-table-thead > tr > th {padding: 3px;font-size: 14px;}
  .commonTable.isSmall .ant-table-tbody td {padding: 8px 6px;font-size: 12px;}
  .commonTable.isSmall .ant-pagination-item {padding:0 6px;width:auto;min-width: auto;height: 22px;line-height: 22px;margin-top:5px;
    a{padding: 0;}
  }
  .commonTable.isBorder tr th,.commonTable.isBorder tr td {border: 1px solid #D9D9D9!important;}

  /* 树 */
  .commonTree.ant-tree .ant-tree-checkbox-inner {border-radius: 2px;}
  .commonTree.ant-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner,
  .commonTree.ant-tree .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {background:#3E92FF!important;border-color:#3E92FF!important;}
  .commonTree.ant-tree .ant-tree-treenode:hover {background: rgba(76, 133, 255, 0.15);}
  .commonTree.ant-tree .ant-tree-switcher {color:#8C939B;}

  // 滚动条样式

  /* 发光边框 */
  .commonBorder {
      border: 1px solid rgb(211 217 226);box-shadow: 0 0 6px rgb(211 217 226) inset;
      &:before,&:after {border-color:#8C939B;}
      > div {
          &:before,&:after {border-color:#8C939B;}
      }
  }

  /* 地图信息 */
  
  /* 对话框 */
  .commonDialog .ant-modal-header {padding: 14px var(--ylr-space) 12px;}
  .commonDialog .ant-modal-header .ant-modal-title {font-size:18px;color: #232426;font-weight: 500;font-family: '微软雅黑', Microsoft Yahei, sans-serif;}
  .commonDialog .ant-modal-content {background:#fff;color: #4A5B86;box-shadow:0px 2px 8px 0px rgba(0,0,0,0.45);border:none;border-radius:0;padding: 0;}
  .commonDialog .ant-modal-header .ant-modal-title::before {width: 4px;height: 16px;background: #31A5FF;border-radius: 3px;margin: 0 10px -1px 0;}
  .commonDialog .ant-modal-header {border-bottom: 1px solid #E6EAEF;}
  .commonDialog .ant-modal-close-x {margin:11px 10px 0 -5px;}
  .commonDialog .ant-modal-close .anticon-close::before {width:14px;height:14px;background-image:url(./image/rtc/white/close.svg);}
  .commonDialog .ant-modal-header::before, .commonDialog .ant-modal-header::after {display: none;}
  .commonDialog .ant-modal-content::before,.commonDialog .ant-modal-content::after,.commonDialog .ant-modal-body::before,.commonDialog .ant-modal-body::after {display: none!important;}
  .commonDialog .ant-modal-footer {text-align: center;}
  .commonDialog .commonDialogMax {top:13px;right: 40px;}
  .commonDialog.isSmall .ant-modal-close-x {margin:11px 10px 0 -5px;}
  .commonDialog.isMax .ant-modal-body {height: calc(100vh - 46px);}
  
  /* 视频播放 */

  /* 动画 */

  /* 段落 */
  .ant-typography {font-size: 16px;color: #4A5B86;margin: auto;}

  /* 气泡浮层 */
  .ant-popover {
    .ant-popover-inner {
      background: #fff;
      border: none;
      box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.15);
    }
    .ant-popover-title {
      color: #4A5B86;
      font-size: 20px;
      max-width: 350px;
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap;
    }
    .ant-popover-arrow:before {
      background: #fff;
    }
  }
}
