.commonThemeCyan {
  --ylr-color-default: rgba(0,242,255,0.1);
  --ylr-color-default-border: rgba(0,242,255,0.6);
  --ylr-color-default-hover: rgba(2, 224, 236, 0.1);
  --ylr-color-primary: rgba(0,242,255,0.8);
  --ylr-color-primary-border: #00F2FF;
  --ylr-color-primary-hover: rgba(3, 224, 235, 0.8);
  --ylr-color-primary2: rgba(0,156,203,0.8);
  --ylr-color-primary2-border: #00BBF3;
  --ylr-color-primary2-hover: rgba(0, 138, 180, 0.8);
  --ylr-color-success: #00b578;
  --ylr-color-warning: #ff8f1f;
  --ylr-color-danger: #B81F38;
  --ylr-color-danger-border: #EF2727;
  --ylr-color-danger-hover: rgba(238, 61, 1, 0.8);
  --ylr-color-border: rgba(0,242,255,0.2);
  --ylr-color-link: #00FAFF;
  --ylr-color-white: #fff;
  --ylr-color-text: #333;
  --ylr-color-text-secondary: #666;
  --ylr-color-weak: #999;
  --ylr-color-light: #ccc;
  --ylr-color-scroll: #00F2FF;
  --ylr-color-bg-title: #076A70;
  --ylr-color-bg-content: rgba(68,245,255,0.08);
  --ylr-color-bg-footer: #076A70;
  --ylr-color-bg-avatar-start: #35FDEF;
  --ylr-color-bg-avatar-end: #00A0D9;
  // --ylr-color-box: #f5f5f5;
  // --ylr-color-background: #fff;

  /* 图标 */
  .ico.ico-call-audio {width: 49px;height: 49px;background-image: url(./image/rtc/cyan/call-btn-audio.svg);}
  .ico.ico-call-video {width: 49px;height: 49px;background-image: url(./image/rtc/cyan/call-btn-video.svg);}
  .ico.ico-call-hangup {width: 4149pxpx;height: 49px;background-image: url(./image/rtc/cyan/call-btn-hangup.svg);}
  .ico.ico-call-btn {width: 49px;height: 49px;background-image: url(./image/rtc/cyan/call-btn.svg);}
  .ico.ico-call-btn-on {width: 49px;height: 49px;background-image: url(./image/rtc/cyan/call-btn-on.svg);}
  .ico.ico-call-btn-del {width: 32px;height: 22px;background-image: url(./image/rtc/cyan/call-btn-del.svg);}
  .ico.ico-call-btn-del-on {width: 32px;height: 22px;background-image: url(./image/rtc/cyan/call-btn-del-on.svg);}
  .ico.ico-call-min-audio {width: 106px;height: 106px;background-image: url(./image/rtc/cyan/call-min-audio.svg);}
  .ico.ico-call-min-video {width: 106px;height: 106px;background-image: url(./image/rtc/cyan/call-min-video.svg);}
  .ico.ico-record-audio {cursor: default;width: 15px;height: 12px;background-image: url(./image/rtc/cyan/ico-record-audio.svg);}
  .ico.ico-record-video {width: 15px;height: 12px;background-image: url(./image/rtc/cyan/ico-record-video.svg);}

  /* 输入框 */
  .commonInput{outline: none;padding-left: 5px;width: 190px;height: 32px;background: rgba(0,242,255,0.1);border-radius: 1px;border: 1px solid rgba(0,242,255,0.6);font-size: var(--ylr-font-size);}

  /* 按钮 */
  .commonBtn{min-width:72px;text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);font-family: PingFangSC-Semibold, PingFang SC, sans-serif;font-weight: 600;color: var(--ylr-color-white);line-height: 20px;border-radius: 3px;}
  .commonBtn.ant-btn-default{background: var(--ylr-color-default);border: 1px solid var(--ylr-color-default-border);color: var(--ylr-color-link);}
  
  /* 页卡 */
  .commonTabs.ant-tabs .ant-tabs-ink-bar{background:#086970;height:1px!important;}
  .commonTabs.ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab{border-radius:0;border:2px solid var(--ylr-color-primary);background:none;color:var(--ylr-color-white);width:36px;padding: 79px 0 80px 1px;line-height: 22px;font-size: 16px;font-weight: 600;margin:0;height: 224px;}
  .commonTabs.ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab-active{background:var(--ylr-color-primary);border-color:transparent;}
  
  /* 表单 */
  .commonForm .ant-form-item-label > label {color: #00FAFF;}
  .commonForm .ant-input {background: rgba(0,242,255,0.1)!important;border: 1px solid rgba(0,242,255,0.6);color: var(--ylr-color-white);border-radius: 3px;}
  
  /* 列表 */
  .commonList .ant-list-item {border-bottom: 1px solid var(--ylr-color-border)!important;}

  /* 表格 */
  
  /* 树 */
  .commonTree.ant-tree .ant-tree-checkbox-inner {background:none;border: 1px solid var(--ylr-color-default-border)!important;}
  .commonTree.ant-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner {background:var(--ylr-color-primary);}
  .commonTree.ant-tree .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {background:var(--ylr-color-primary);}

  // 滚动条样式

  /* 发光边框 */
  .commonBorder {
      border: 1px solid rgba(76,220,255, 0.4);box-shadow: 0 0 6px rgb(76 220 255 / 70%) inset;
  }

  /* 地图信息 */
  
  /* 对话框 */
  .commonDialog .ant-modal-content {background:rgba(1, 15, 29, 0.9);border:1px solid #4CDCFF;border-radius:0;padding: 0;}

  /* 视频播放 */

  /* 动画 */
}
